<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">网上展览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/list' }">展览管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{exhibitionTitle}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id }">内容设置</el-breadcrumb-item>
      <el-breadcrumb-item>洽谈室管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="menu-area">
        <el-button type="primary" @click="baseSetting">洽谈室设置</el-button>
      </div>
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="m_id" label="房间iD"></el-table-column>
        <el-table-column prop="title" label="洽谈室名称"></el-table-column>
        <el-table-column prop="anchor" label="房主账号"></el-table-column>
        <el-table-column prop="start_time" label="房间开启时间">
          <template slot-scope="scope">
            {{datafromatfull(scope.row.start_time)}}
          </template>
        </el-table-column>
        <el-table-column prop="end_time" label="房间结束时间">
          <template slot-scope="scope">
            {{datafromatfull(scope.row.end_time)}}
          </template>
        </el-table-column>
        <el-table-column prop="end_time" label="当前房间状态">
          <template slot-scope="scope">
            <el-tag type="success" effect="plain" v-if="scope.row.status === 1">正常</el-tag>
            <el-tag type="info" effect="plain" v-if="scope.row.status === 2">待审核</el-tag>
            <el-tag type="danger" effect="plain" v-if="scope.row.status === 3">已拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="online" label="当前在线人数" width="120"></el-table-column>
        <el-table-column fixed="right" label="操作" width="380">
          <template slot-scope="scope">
            <el-button size="small" type="info" @click="viewList(scope.row.m_id)">查看用户列表</el-button>
            <el-button type="danger" size="small" @click="dismissRoom(scope.row.m_id)">解散房间</el-button>
            <el-button type="success" size="small" @click="setStatus(scope.row.m_id, 1)">通过审核</el-button>
            <el-button type="danger" size="small" @click="setStatus(scope.row.m_id, 3)">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 基础设置 -->
    <el-dialog title="洽谈室设置" :visible.sync="settingVisible" width="30%" :before-close="settingClose">
      <el-form class="middle-form" ref="setRef" :model="setForm" label-width="120px">
        <el-form-item label="创建房间方式" prop="type">
          <el-radio-group v-model="setForm.type">
            <el-radio :label="1">自动创建</el-radio>
            <el-radio :label="2">需要审核</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="单房间最大人数" prop="max">
          <el-input-number size="mini" v-model="setForm.max" :step="1" :min="2" :max="50" step-strictly></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitSetting">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看用户列表 -->
    <el-dialog title="用户列表" :visible.sync="userListVisible" width="800" :before-close="userListClose">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="nick" label="用户昵称"></el-table-column>
        <el-table-column prop="phone" label="用户手机号"></el-table-column>
        <el-table-column prop="enter_time" label="进入房间时间">
          <template slot-scope="scope">
            {{datafromatfull(scope.row.enter_time)}}
          </template>
        </el-table-column>
        <el-table-column prop="leave_time" label="离开房间时间">
          <template slot-scope="scope">
            {{datafromatfull(scope.row.leave_time)}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="danger" size="small" @click="kickOut(scope.row.room_id, scope.row.m_id)">踢出房间</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      dataList: [],
      exhibitionTitle: '',
      settingVisible: false,
      setForm: {
        m_id: this.$route.params.m_id,
        type: 1,
        max: 10
      },
      userList: [],
      userListVisible: false
    }
  },
  created () {
    this.getPageData()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/exhibition-negotiate-list', { params: { m_id: this.$route.params.m_id } })
      if (res.status === 200) {
        this.dataList = res.data.list
        this.setForm.type = res.data.setting.type
        this.setForm.max = res.data.setting.max
        this.exhibitionTitle = window.sessionStorage.getItem('ex_name')
      } else {
        this.$message.error(res.msg)
      }
    },
    baseSetting () {
      this.settingVisible = true
    },
    settingClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    async submitSetting () {
      const { data: res } = await this.$http.post('/exhibition-negotiate-list', this.setForm)
      if (res.status === 200) {
        this.$message.success(res.msg)
        this.settingVisible = false
      } else {
        this.$message.error(res.msg)
      }
    },
    async viewList (mid) {
      const { data: res } = await this.$http.get('/exhibition-negotiate-user-list', { params: { m_id: mid } })
      if (res.status === 200) {
        this.userList = res.data
        this.userListVisible = true
      } else {
        this.$message.error(res.msg)
      }
    },
    userListClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    // 解散房间
    async dismissRoom (mid) {
      const { data: res } = await this.$http.delete('/exhibition-negotiate', { params: { m_id: mid } })
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getPageData()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 设置房间状态
    async setStatus (mid, status) {
      const { data: res } = await this.$http.put('/exhibition-negotiate', { m_id: mid, status: status })
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getPageData()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 将用户踢出房间
    async kickOut (roomid, usermid) {
      const { data: res } = await this.$http.post('/exhibition-negotiate-kick', { room_mid: roomid, user_mid: usermid })
      if (res.status === 200) {
        await this.viewList()
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    datafromatfull (time) {
      time = parseInt(parseInt(time) / 1000)
      return this.$moment.unix(time).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<style scoped>
.menu-area{
  padding: 0 0 20px 0;
  text-align: right;
}
.banner-img{
  max-width: 50px;
  max-height: 50px;
}
</style>
